/* eslint-disable complexity */
import Table from 'components/Table';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { Order } from 'utils/types';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { formatToCurrency } from 'utils/formatting';
import Box from '@mui/material/Box';
import { TableBody, Typography } from '@mui/material';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';

interface DealInformationProps {
  order?: Order;
}

const TransactionSummary = ({ order }: DealInformationProps) => {
  return (
    <Box component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Transaction Summary</Typography>
      </MuiStyledSectionHeader>
      <Table noPadding={true}>
        <TableBody>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Product Base Currency</MuiStyledTableCell>
            {/* TODO: At the moment we are using USD for all CASH orders but this should be updated */}
            <MuiStyledTableCell align="right">USD</MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Total Actual Deliverable</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {order?.settlement &&
                renderOrderValue(
                  Boolean(order?.tradesTotalWithTotalFee),
                  order?.tradesTotalWithTotalFee
                )}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>
              {order?.type === 'REDEMPTION' && order.deliveryType === 'CASH'
                ? 'Estimated Deliverable'
                : 'Amount Pre-Funded'}
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {renderOrderValue(
                Boolean(order?.deliveries?.expectedTotalCash),
                order?.deliveries?.expectedTotalCash
              )}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Total Fees</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {renderOrderValue(Boolean(order?.tradesTotalFees), order?.tradesTotalFees)}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell sx={{ '&.MuiTableCell-root': { paddingLeft: '48px' } }}>
              Admin Fee
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {renderOrderValue(Boolean(order?.tradesAdminFee), order?.tradesAdminFee)}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell sx={{ '&.MuiTableCell-root': { paddingLeft: '48px' } }}>
              Execution Fee
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {renderOrderValue(Boolean(order?.tradesExecutionFee), order?.tradesExecutionFee)}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Total Slippage</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {renderOrderValue(Boolean(order?.slippage), order?.slippage)}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Amount to Settle (Base)</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {order?.type === 'REDEMPTION' && order.deliveryType === 'CASH'
                ? formatToCurrency(order?.tradesTotalWithTotalFee, 'USD')
                : formatToCurrency(order?.slippage, 'USD')}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default TransactionSummary;
