import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { NavReviewItemWithProduct } from 'utils/types/nav';
import { NavReviewTableColumnsConfig, NavReviewTableColumnsConfigFA } from './tableColumnsConfig';
import { NavReviewTableRow } from './NavReviewTableRow';
import { ParamsType } from 'hooks/useUrlParams';
import { SortingOrder } from 'utils/types';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { format } from 'date-fns';
import { StyledEmptyList } from 'shared/Tables/table.styles';
import { useUserPermissions } from 'store/user/selectors';

interface NavReviewTableProps {
  loading: boolean;
  queryParams: ParamsType;
  setQueryParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  navReviewItems: NavReviewItemWithProduct[];
  editAction?: (partner: NavReviewItemWithProduct) => void;
}

function sortItems(
  navReviewItems: NavReviewItemWithProduct[],
  sort: NonNullable<ParamsType['sort']>
) {
  const sortArray = typeof sort === 'string' ? [sort] : sort;
  const sortOptions = sortArray.reduce((sortFieldsAndOrders, sortOption) => {
    const [field, sortOrder] = sortOption.split(':');
    // using rawDifference which is a number instead of difference which is a string
    const fieldName = field === 'difference' ? 'rawDifference' : field;
    sortFieldsAndOrders[fieldName] = sortOrder as SortingOrder;

    return sortFieldsAndOrders;
  }, {} as Record<string, SortingOrder>);

  return orderBy(navReviewItems, Object.keys(sortOptions), Object.values(sortOptions));
}

const NavReviewTable = ({
  loading,
  navReviewItems,
  editAction,
  queryParams,
}: NavReviewTableProps) => {
  const user = useUserPermissions();
  const [sort, setSort] = useState<ParamsType['sort']>([]);
  const sortedItems = !Boolean(sort) ? navReviewItems : sortItems(navReviewItems, sort!);

  return (
    <>
      <Table noPadding>
        <TableHeaderWithMultiSort
          defaultSortedColumns={sort || []}
          columns={
            user?.isFundAccount ? NavReviewTableColumnsConfigFA : NavReviewTableColumnsConfig
          }
          onColumnSort={setSort}
        />
        <TableBodyWithStates
          loadingData={Boolean(loading)}
          hasContent={!isEmpty(sortedItems)}
          noContentLabel={
            queryParams.valuationDate === format(new Date(), DEFAULT_DATE_FORMAT) ? (
              <StyledEmptyList>
                No NAV files have been uploaded yet for this day. <br />
                Please check back later.
              </StyledEmptyList>
            ) : (
              <StyledEmptyList>There is no content available for this day.</StyledEmptyList>
            )
          }
        >
          {sortedItems?.map((navReviewItem) => (
            <NavReviewTableRow
              key={navReviewItem._id}
              navReviewItem={navReviewItem}
              editAction={() => {
                if (editAction) editAction(navReviewItem);
              }}
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
};

export default NavReviewTable;
