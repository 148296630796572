import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types/orders';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { ProductType } from 'utils/types';
import { formatToCurrency } from 'utils/formatting';
import { useMemo } from 'react';
import { TableBody } from '@mui/material';

export const ExpectedDeliverablesTableColumnsConfig = [
  { label: 'Trade Date', propName: 'tradeDate', propType: 'string', width: '70%', sort: false },
  { label: 'Ticker', propName: 'ticker', propType: 'string', sort: false },
  { label: 'Quantity', propName: 'amount', propType: 'string', sort: false },
  { label: 'Price (T-1 NAV)', propName: 'priceT1', propType: 'string', sort: false },
  { label: 'Value', propName: 'value', propType: 'string', sort: false },
  { label: 'FX rate to USD', propName: 'fxRateUSD', propType: 'string', sort: false },
  { label: 'Price (USD)', propName: 'priceUSD', propType: 'string', sort: false },
  { label: 'Value (USD)', propName: 'valueUSD', propType: 'string', sort: false },
  { label: 'Creation Fees', propName: 'creationFees', propType: 'string', sort: false },
];

interface ExpectedDeliverablesExpectedTableProps {
  productType: ProductType;
  order?: Order;
}

export const ExpectedDeliverablesExpectedTable = ({
  productType,
  order,
}: ExpectedDeliverablesExpectedTableProps) => {
  const deliverables = order?.deliveries?.expected;
  const columns = useMemo(() => {
    const col = ExpectedDeliverablesTableColumnsConfig;
    const lastCol =
      order?.type === 'REDEMPTION' && order.deliveryType === 'CASH'
        ? 'Estimated Deliverable'
        : 'Amount Pre-Funded';

    return [
      ...col,
      {
        label: lastCol,
        propName: lastCol === 'Estimated Deliverable' ? 'estimatedDeliverable' : 'amountPreFunded',
        propType: 'string',
      },
    ];
  }, [order]);

  const tokensColumns = [
    { label: 'Ticker', propName: 'ticker', propType: 'string' },
    { label: 'Quantity', propName: 'quantity', propType: 'number' },
  ];

  return (
    <>
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort columns={columns} />
          <TableBody>
            {deliverables?.map((deliverable: Deliverable) => (
              <MuiStyledTableRow key={deliverable.ticker}>
                <MuiStyledTableCell key="tradeDate">{order?.dealDate}</MuiStyledTableCell>
                <MuiStyledTableCell key="ticker">{deliverable?.ticker}</MuiStyledTableCell>
                <MuiStyledTableCell key="coinQty">{deliverable.amount}</MuiStyledTableCell>
                <MuiStyledTableCell key="priceT1Nav">
                  {formatToCurrency(deliverable.price, deliverable.currency)}
                </MuiStyledTableCell>
                <MuiStyledTableCell key="value">
                  {formatToCurrency(deliverable.totalInCurrency, deliverable.currency)}
                </MuiStyledTableCell>
                {/* TODO: this will be updated */}
                <MuiStyledTableCell key="fxRateToUSD">1</MuiStyledTableCell>
                <MuiStyledTableCell key="priceUSD">${deliverable.price}</MuiStyledTableCell>
                <MuiStyledTableCell key="valueUSD">
                  ${deliverable.totalInCurrency}
                </MuiStyledTableCell>
                <MuiStyledTableCell key="creationFees">
                  {formatToCurrency(deliverable.executionFee, deliverable.currency)}
                </MuiStyledTableCell>
                <MuiStyledTableCell key="amountToPreFund">
                  {formatToCurrency(deliverable.totalWithFees, deliverable.currency)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort
            columns={tokensColumns}
            columnAlignment={{ quantity: 'right' }}
          />
          <TableBody>
            {deliverables?.length ? (
              deliverables?.map((deliverable: Deliverable, index) => (
                <MuiStyledTableRow key={index}>
                  <MuiStyledTableCell key="ticker">{deliverable?.ticker}</MuiStyledTableCell>
                  <MuiStyledTableCell align="right" key="coinQty">
                    {deliverable.amount}
                  </MuiStyledTableCell>
                </MuiStyledTableRow>
              ))
            ) : (
              <MuiStyledTableRow>
                <MuiStyledTableCell key="ticker">-</MuiStyledTableCell>
                <MuiStyledTableCell align="right" key="cointQty">
                  -
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};
